import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getLaborCosts({ date, interval = 0, endDate = null }) {
    return axiosIns.get(
      '/admin/labor_costs',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
    )
  },
}
