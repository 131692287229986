import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getDetail({ date, interval = 0, endDate = null }) {
    return axiosIns.get(
      'admin/sales/detail',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
    )
  },
  getPerformance({ date, interval = 0, endDate = null }) {
    return axiosIns.get(
      'admin/sales/performance',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
    )
  },
}
