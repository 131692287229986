import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getExpenses({ date, interval = 0, endDate = null }) {
    return axiosIns.get(
      '/admin/expenses',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
    )
  },
  createExpenses(expenseValues = []) {
    const formData = serialize(snakecaseKeys({
      expenses: expenseValues,
    }))

    return axiosIns.post('/admin/expenses/create_all', formData)
  },
  updateExpenses(expenseValues = []) {
    const formData = serialize(snakecaseKeys({
      expenses: expenseValues,
    }))

    return axiosIns.put('/admin/expenses/update_all', formData)
  },
  deleteExpenses(ids) {
    return axiosIns.delete(
      '/admin/expenses/destroy_all',
      {
        params: { ids },
      },
    )
  },
  saveAll({ expenses = [] }) {
    const formData = serialize(snakecaseKeys({ expenses }))

    return axiosIns.put('admin/expenses/save_all', formData)
  },
}
