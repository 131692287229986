import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getFinances({ date, interval = 0, endDate = null }) {
    return axiosIns.get(
      '/admin/finances',
      {
        params: snakecaseKeys({
          date,
          interval,
          endDate,
        }),
      },
    )
  },
  getFinance({ date, interval = 0, endDate = null }) {
    return axiosIns.get(
      `/admin/finances/${date}`,
      {
        params: snakecaseKeys({
          interval,
          endDate,
        }),
      },
    )
  },
  getIncomeDetail({ date, interval = 0, endDate = null }) {
    return axiosIns.get(
      `/admin/finances/${date}/income_detail`,
      {
        params: snakecaseKeys({
          interval,
          endDate,
        }),
      },
    )
  },
  getMoneyIncomeDetail({ date, interval = 0, endDate = null }) {
    return axiosIns.get(
      `/admin/finances/${date}/money_income_detail`,
      {
        params: snakecaseKeys({
          interval,
          endDate,
        }),
      },
    )
  },
  getSpendingDetail({ date, interval = 0, endDate = null }) {
    return axiosIns.get(
      `/admin/finances/${date}/spending_detail`,
      {
        params: snakecaseKeys({
          interval,
          endDate,
        }),
      },
    )
  },
  getMoneySpendingDetail({ date, interval = 0, endDate = null }) {
    return axiosIns.get(
      `/admin/finances/${date}/money_spending_detail`,
      {
        params: snakecaseKeys({
          interval,
          endDate,
        }),
      },
    )
  },
  getIncomeDetailList({
    date,
    interval = 0,
    isDailyListBetweenIntervalRange = false,
    endDate = null,
  }) {
    return axiosIns.get(
      `/admin/finances/${date}/income_detail_list`,
      {
        params: snakecaseKeys({
          interval,
          isDailyListBetweenIntervalRange,
          endDate,
        }),
      },
    )
  },
  getMoneyIncomeDetailList({
    date,
    interval = 0,
    isDailyListBetweenIntervalRange = false,
    endDate = null,
  }) {
    return axiosIns.get(
      `/admin/finances/${date}/money_income_detail_list`,
      {
        params: snakecaseKeys({
          interval,
          isDailyListBetweenIntervalRange,
          endDate,
        }),
      },
    )
  },
  getSpendingDetailList({
    date,
    interval = 0,
    isDailyListBetweenIntervalRange = false,
    endDate = null,
  }) {
    return axiosIns.get(
      `/admin/finances/${date}/spending_detail_list`,
      {
        params: snakecaseKeys({
          interval,
          isDailyListBetweenIntervalRange,
          endDate,
        }),
      },
    )
  },
  getMoneySpendingDetailList({
    date,
    interval = 0,
    isDailyListBetweenIntervalRange = false,
    endDate = null,
  }) {
    return axiosIns.get(
      `/admin/finances/${date}/money_spending_detail_list`,
      {
        params: snakecaseKeys({
          interval,
          isDailyListBetweenIntervalRange,
          endDate,
        }),
      },
    )
  },
  getMoneyProfit({
    date,
    interval = 0,
  }) {
    return axiosIns.get(
      `/admin/finances/${date}/money_profit`,
      {
        params: snakecaseKeys({
          interval,
        }),
      },
    )
  },
}
